<script lang="ts" setup>
const RESEND_EMAIL_TIMEOUT_MILLISECONDS = 599_000;
const { t } = useT();
const { open } = useFunrizeModals();
const { emailVerificationTimerCookie } = useEmailVerificationTimerCookie();
const { duration, durationLeft, reset } = useCountdown({
	timestamp: Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS,
	formatToken: "mm[ : ]ss"
});
const handleRequestEmailConfirm = () => {
	if (duration.value > 0) {
		return;
	}

	dispatchGAEvent({
		event: "verification",
		location: "header",
		step: "info",
		type: "email"
	});
	open("LazyOModalEmailConfirm", { immediateRequest: true });
};

watch(emailVerificationTimerCookie, (value) => {
	if (value) {
		reset(Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS);
	}
});
</script>
<template>
	<div class="email-notify text-cannes" :class="{ 'is-sent': duration > 0 }" @click="handleRequestEmailConfirm">
		<NuxtIcon name="20/warning" />

		<AText as="div" variant="toledo">
			<i18n-t keypath="An email was sent to you. Click the {key1} to complete the sign-up! {key2}">
				<template #key1>
					<b>{{ t("verification link") }}</b>
				</template>
				<template #key2>
					<br class="hide-up-lg" />
					<AText v-if="duration > 0" class="resend" :modifiers="['bold', 'inheritSize']">
						{{ t("Resend") }} ({{ durationLeft }})
					</AText>
					<template v-else>
						<b>{{ t("No email?") }}&nbsp;</b>
						<AText class="resend" :modifiers="['underline', 'inheritSize']">{{ t("Click here to resend") }}</AText>
					</template>
				</template>
			</i18n-t>
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.email-notify {
	width: 100%;
	min-height: 54px;
	padding: gutter(1) gutter(2);
	gap: gutter(1);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--chimoio);
	z-index: 2;
	line-height: 20px;

	&:not(.is-sent) {
		&:hover {
			cursor: pointer;
			opacity: 0.85;
		}
	}

	.nuxt-icon {
		font-size: 20px;
	}

	@include media-breakpoint-up(lg) {
		.hide-up-lg {
			display: none;
		}
	}

	@include media-breakpoint-down(lg) {
		min-height: 84px;
		align-items: flex-start;
		padding: gutter(1) gutter(2);

		.toledo {
			font-size: 14px;
		}

		.resend {
			display: flex;
			margin-top: gutter(2);
		}
	}
}
</style>
